export default {
	// api请求地址
	// baseUrl: 'https://www.dinglan.net',
	// 图片域名
	// imgDomain: 'https://www.dinglan.net',
	// 前端域名
	// webDomain: '',
	// 腾讯地图key
	// mpKey: '',
	// 客服
	// webSocket: '',
	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,
	baseUrl: "https://mall.youcaiji.com",
	imgDomain: "https://mall.youcaiji.com",
	webDomain: "https://mall.youcaiji.com/web",
	mpKey: "IZQBZ-3UHEU-WTCVD-2464U-I5N4V-ZFFU3",
	webSocket:"wss://mall.youcaiji.com/wss",
}